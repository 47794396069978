* {
  vertical-align: baseline;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: none;
  padding: 0;
  margin: 0;
}
body {
  padding: 5px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
blockquote,
form,
ul,
ol,
dl {
  margin: 20px 0;
  color: cornflowerblue;
}

a,
a:hover,
a:active,
a:visited {
  color: cornflowerblue;
}

dd,
blockquote {
  margin-left: 40px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@import-normalize;

.App {
  margin: 1rem;
  padding: 1rem;
}

.App .mainContent {
  max-width: 800px;
  width: 100%;
  margin: 3rem auto 0;
}

.App a {
  text-decoration: none;
}

.App .Nav {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  /* border: thin solid red; */
}

.App .Nav header {
  text-align: center;
}

.App .Nav ul a:hover {
  text-decoration: underline;
}

.App .Nav ul li a.active {
  text-decoration: line-through;
}

.App .Nav ul {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.App .Nav ul li {
  /* border: thin solid red; */
  text-align: center;
}
